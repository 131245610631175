import React, { useState, useCallback, useMemo } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import safeGet from 'lodash.get';

const RealSizePicture = ({ fileRelativePath, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { internal: {}, relativeDirectory: { regex: "images/" } }
      ) {
        nodes {
          relativePath
          childImageSharp {
            fixed(height: 960) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () =>
      data.allFile.nodes.find(({ relativePath }) => {
        return fileRelativePath === relativePath;
      }),
    [data, fileRelativePath]
  );

  const fixed = safeGet(match, 'childImageSharp.fixed');

  return fixed ? <Img fixed={fixed} Tag="div" {...props} /> : null;
};

const ContainerZoomImg = ({
  title,
  img,
  relativePath,
  thumbailClass,
  realSizeClass,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <ControlledZoom
      key={title}
      isZoomed={isZoomed}
      onZoomChange={handleZoomChange}
      zoomMargin={20}
    >
      {isZoomed ? (
        <RealSizePicture
          fileRelativePath={relativePath}
          className={realSizeClass}
        />
      ) : (
        <Img fixed={img} alt={title} className={thumbailClass} />
      )}
    </ControlledZoom>
  );
};

export default ContainerZoomImg;
